import React from "react";

export default function contactme() {
  return (
    <div className="aboutme">
      <br/>
      <h1>Felipe Villa</h1>
      <br/>
      <h3>
        I am new to the tech scene and looking for oppertunities. I enjoy Web
        design and learning new technologies. Resently I have been learning
        Three.js and becoming familar with the new Web3 model. I was trained in
        the use of React.js with a focus on Node as the basis of my
        webdevelopment.
      </h3>
      <br/>
      <h3> Please visit my Github for more on code I have worked on</h3>
      <br/>
      <a href="https://github.com/Felipevillajr"><h3>GitHub Link!</h3></a>
    </div>
  );
}
